
.contactP{
    width: 100%;
 
 position: relative;
 margin-top: 40px;
}



.contactC{
 display: grid;
 grid-template-columns:  1fr 1fr;

    
}

.contactT{
    display: flex;
    flex-direction: column;
   justify-content: center;
}

.contactT h1{
    font-size: 55px;
    font-weight: 800;
}



.contactT p{
    font-size: 18px;
    font-weight: 500;
    max-width: 400px;
}


.contactImg{
    display: flex;
    align-items: center;
    justify-content: center;
}





.plusCircle{
    position: absolute;
    top: 100px;
    left: 600px;
}














/* icon flex */

.iconFlexP{
    position: relative;
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.a{
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
 }
 .b{
     position: absolute;
 bottom: 0;
 left: 0;
     width: 100%;
     height: 100%;
     z-index: -1;
 
  
 }
 
 
 .iconFlexC{
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   gap: 50px;

   margin-top: 20px;
 }


 .iconSec1{
     text-align: center;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
 }

 .iconSec1 h3{
     font-weight: bold;
     font-size: 25px;
     margin-top: 15px;
 }



















.ContactFormP{
    margin-top:140px;
    margin-bottom: 200px;
}

 .ContactFormC form{
     padding: 40px 30px;
     border-radius: 20px;
   
     background-color: white;
     
     box-shadow: -1px 0px 36px 16px rgba(0,0,0,0.08);
-webkit-box-shadow: -1px 0px 36px 16px rgba(0,0,0,0.08);
-moz-box-shadow: -1px 0px 36px 16px rgba(0,0,0,0.08);


 }


 .ContactFormC .formSec1{
     position: relative;
     width: 100%;
     height: 50px;

border-radius: 5px;
margin-bottom: 40px;
 }



 .ttttt{
     margin-bottom: 20px !important;
 }

 .ContactFormC .formSec1 input{
     width: 100%;
     height: 100%;
     border: none;
     padding-left: 15px;
border-radius: 5px;
border: 1px solid black;   

font-weight: 600;
color: rgb(29, 29, 29);
     
     outline: none;
 }

.formSec1 input::placeholder{
    font-weight: 600;
    color: rgba(29, 29, 29, 0.336);
}


 .ContactFormC .formSec1 label{
     height: 100%;
     position: absolute;
     top: -10px;
     left: 10px;
     transition: 0.3s;
    display: block;
    padding: 0 5px;
    height: fit-content;
    background-color: white;
user-select: none;
    font-size: 13px;

 }




 select{
    width: 100%;
     height: 100%;
     border: none;
     padding-left: 15px;
border-radius: 5px;
border: 1px solid black;   

font-weight: 600;


background-color: white;
     position: relative;
     outline: none;
 }





 option:checked{
    font-weight: 600;
    color: black;

    
 }


 .ttttt{
    min-height: 150px;
}

 .formSec1 textarea{
    width: 100%;
   min-height: 100%;
    border: none;
    padding-left: 15px;
border-radius: 5px;
font-weight: 600;
color: rgb(29, 29, 29);
    padding-top: 20px;
border: 1px solid black;   

    outline: none;
    resize: none;
 }

.checkboxCont{
    display: flex;
    gap: 10px;
}


.checkboxCont input{
 margin-top: 3px;
 min-height: 20px;
 min-width: 20px;
}



.ContactFormC button{
    padding: 16px 30px;
    border-radius: 8px;
    background-color: #EF7823;
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    cursor:pointer;
    border:none;
    transition: 0.3s;
}


.ContactFormC button i{
    font-size: 20px;
}


.ContactFormC button:hover{
    background-color: #db6b1b;
}






.ContactFormC{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 90px;
}



.formImg img{
    width:600px;
}










/* responsive */

@media (max-width:1238px){
    .plusCircle{
        left: 400px;
    }
}


@media (max-width:934px){
    .plusCircle{
        left: 200px;
        z-index: -1;
    }
}



@media (max-width:840px){
    .contactC{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 40px;
    }

    .contactImg{
        width: 100%;
    }
.iconFlexP{
    height: auto;
    padding-top:200px;
    padding-bottom: 200px;

}

.contactP{
    transform: translateY(20px);
    margin-top: 0;
}



.a{
    display: none;
}
    .iconFlexC{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 70px;
        flex-direction: column;
        
    }

    .b{
        min-width: 100% !important;
        object-fit: cover !important;
    }

    .iconSec1{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }
}


@media (max-width:432px){
    
.contactT h1{
    font-size: 44px;
    font-weight: 800;
}
}





















.formImg .detailsOfForm{
            max-width: 100%;
            margin-bottom: 30px;
            display: flex;
            align-items:center;
            justify-content: center;
            flex-direction: column;
        }

        .formImg .detailsOfForm p{
            font-weight: 700;
            font-size: 19px;
            color: #0b92a7;

        }




        .formImg .detailsOfForm h3{
            font-weight: 800;
            font-size: 35px;
            color: black;
            opacity: 0.7;
            margin-top: 5px;

        }










.upper{

    display: none;

 }
@media (max-width:936px){

        .formImg .detailsOfForm{
            display: none !important;
        }
    .upper{
            max-width: 100%;
            margin-bottom: 30px;
            display: flex;
            align-items:center;
            justify-content: center;
            flex-direction: column;
        }

        .upper p{
            font-weight: 700;
            font-size: 19px;
            color: #0b92a7;

        }

.upper div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}


        .upper h3{
            font-weight: 800;
            font-size: 35px;
            color: black;
            opacity: 0.7;
            margin-top: 5px;

        }


.ContactFormC{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 70px;
    flex-direction: column-reverse;
}


.ContactFormC form{
    margin: 0 auto;
}


.formImg{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

}



@media (max-width:644px){
    .ContactFormC button{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
.formImg img{
    min-width:100% !important;
}


.contactImg img{
    width:100%;
}
}


@media (max-width:432px){
    .checkboxCont p{
        font-size: 13px;
    }
}



.formSec1 input:focus {
    border: 2px solid #3bb3c5 !important;
    }
    

    .formSec1 select:focus {
        border: 2px solid #3bb3c5 !important;
        }
        


    .formSec1 textarea:focus {
        border: 2px solid #3bb3c5 !important;
        }
        




















        @media (max-width:1178px){

.formImg img{
    width:550px;
}


        }



        @media (max-width:1088px){

.formImg img{
    width:500px;
}


        }






        @media (max-width:1014px){

.formImg img{
    width:450px;
}


        }







        @media (max-width:728px){
            .iconFlexP{
                margin-top: 0px !important;
                margin-bottom: 0px !important;

            }
            
        }





        @media (max-width:1172px){
            .ContactFormP{
                margin-bottom: 100px;
            }
        }







.iconSec1 hr{
    margin-top: 10px;
    margin-bottom: 10px;

}


        .iconSec1:nth-child(1) hr{
            min-width: 120px !important;
            text-align: left;
            border: none;
            background-color: #EF7823;
            height: 2px;
            padding: 0;
        }




        .iconSec1:nth-child(2) hr{
            min-width: 120px !important;
            text-align: left;
            border: none;
            background-color: #248594;
            height: 2px;
            padding: 0;
        }




        .iconSec1:nth-child(3) hr{
            min-width: 120px !important;
            text-align: left;
            border: none;
            background-color: #EF7823;
            height: 2px;
            padding: 0;
        }







        @media (max-width:482px){


.ContactFormP{
    margin-bottom: 0px;
}
        }





