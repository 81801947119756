/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-alice-carousel/lib/alice-carousel.css";

*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Assistant', sans-serif;
}


html{
  overflow-x: hidden;

}

body{
  overflow-x: hidden;
  
}

.parent{
  width: 100%;

}


.child{
  max-width: 1570px;
  margin: 0 auto;
padding: 0 40px;
width:100%;

}




@media (max-width:600px){
  .child{
    padding: 0 30px;

  }
}



a{
  text-decoration: none;
  color:black;
}