



.flex1C{
       display: flex;
       align-items: center;
       justify-content: flex-start;
       z-index: 3;
}


.flex1Txt h1{
    font-size: 44px;
    font-weight: 800;
    line-height: 1.2;

}

.flex1Txt p{
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 40px;
    max-width: 600px;
}




.flex1Txt a{
    padding: 15px 30px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    background-color: #f7a919;
}













.flex2P{
    margin-top: 120px;
    margin-bottom:80px;
}

.flex2C{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.flex2Img img{
width: 400px;
}
.flex2Txt{
    max-width: 600px;
}



.flex2Txt h1{
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: 800;
    line-height: 1.2;
}



.flex2Txt p{
    font-size: 18px;
}













.iconsOflex{
    display: flex;
    flex-direction: column;
    gap: 20px;
}




.iconIflex{
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 18px;
    font-weight: 600;
}

.iconIflex img{
   width: 30px;
}



.flex3Text h3{
    font-size: 46px;
    font-weight: 800;
    margin-bottom: 25px;

}



.flex3Text p{
    font-size: 19px;
    margin-bottom: 40px;
    margin-top: 20px;
    max-width: 600px;

}


.flex3Text a{
    padding: 15px 30px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    background-color: #f7a919;
    transition: 0.3s;
}






.flex3Text a:hover{
    background-color: #f09d02;
   
}



.flex3Text{
    max-width: 700px;
    z-index: 3;

}




.flex3C{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}







.flex3P{
    padding-top: 80px;
    padding-bottom: 200px;
    background-color: rgb(245, 245, 245);
  
    background-position: center;
    background-size: cover;
    position: relative;
}

.darkBg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.664);
    display: none;
}

.bgImage{
    position: absolute;
    top: 0;
    right: 0;
    object-fit: cover;
    object-position: center;
    height: 100%;
}













.realGrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 50px;
  row-gap: 70px;
  margin-top: 70px;
}



.rGrid1 h2{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 10px;
}


.rGrid1 .line{
    max-width: 100px;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
    height: 2px;
    background-color: #f8c5a0;  
}


.rGrid1 .lineB{
    background-color: #86e3f1;
}
















.coloredBgP{
    position: relative;
  padding-top: 130px;
  padding-bottom: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 170px;
  margin-bottom: 80px;


}


.particle{
    position: absolute;
    top: 60px;
    left: 60px;
}
.bgColored{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
}





.coloredBgC{
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    z-index: 3;
    
}


.colorBgText{
    z-index: 3;
}

.colorBgImg{
    z-index: 3;
}

.colorBgImg img{
    max-width: 500px;
    margin-bottom: -70px;
}



.colorBgText h3{
    font-size: 50px;
    font-weight: 800;
    margin-bottom: 15px;
    line-height: 1.2;
}





.colorBgText p{
    font-size: 20px;
    margin-bottom: 40px;
   
}




.colorBgText a{
    padding: 15px 50px;
    color: white;
    font-weight: bold;
    font-size: 17px;
    background-color: #f7a919;
    transition: 0.3s;
}






.colorBgText a:hover{
    background-color: #f09d02;
   
}












.contentGrid{
    max-width: 1100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0 auto;
    gap: 20px;
    margin-top: 100px;
}



.contentGrid1 img{
    margin-bottom: 10px;
    border-radius: 10px;
    width:100%;
}


.contentGrid1 h3 {
    font-size: 18px;
    opacity: 0.9;
    font-weight: 700;
}






.viewMore{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.viewMore a{
    padding: 15px 30px;
    font-weight: bold;
    color: white;
    font-size: 17px;
    border-radius: 8px;
    background-color: #f7a919;
    transform: 0.3s;
    margin-top: 80px;
    margin-bottom: 60px;
}


.viewMore a:hover{
    background-color: #f09d02;

}











/* responsive */

.flex1Img img{
    min-width: 500px;
}


@media (max-width:1065px){
    .flex1Img img{
        min-width: 450px;
        
    }


    
.flex2Img img{
    min-width: 300px;
    }
    
}




@media (max-width:983px){
    .flex1Img img{
        min-width: 400px;
        
    }
    
}




@media (max-width:933px){
    .flex1C{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 20px;
        gap: 120px;
    }

.flex1Img{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
}





@media (max-width:577px){
    .flex1Img img{
        min-width: 100%;
        
    }

.flex1Txt h3{
    font-size: 38px;
  

}

.flex1Txt p{
    font-size: 18px;

}




.flex1Txt a{
    padding: 15px 25px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    background-color: #f7a919;
}


      
}



@media (max-width:445px){
    .flex1Txt h3{
        font-size: 35px;
      
    
    }
    
    .flex1Txt p{
        font-size: 16px;
    
    }

    .flex1Txt a{
        padding: 15px 20px;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        background-color: #f7a919;
    }
    
}




@media (max-width:879px){

.flex2C{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 60px;
    flex-direction: column;
    margin-bottom: 0px !important;
}

.flex2Txt{
    min-width:100%;
}

.flex2Img{
    display: none;
}
}




@media (max-width:519px){


.flex2Txt h3{
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 800;
    line-height: 1.2;
}



.flex2Txt p{
    font-size: 16px;
}

}





@media (max-width:1099px){
  .darkBg{
      display: flex !important;
      z-index: 2;
  }

  .bottomWavvy{
      z-index: 70 !important;
  }


  .topWavvy{
    z-index: 70 !important;
}



.wavyLine{
    display: none;
}
.bgImage{
    z-index: 0;
}

  .flex3C{
      z-index: 30;
      color: white;
  }

  .iconIflex img{
    filter: brightness(0) invert(1);
  }


.flex3Text p{
    font-size: 19px;
    font-weight: 500;

}
}




@media (max-width:431px){
   
.flex3Text a{
    padding: 15px 25px;
    color: white;
    font-weight: bold;
    font-size: 15px;
    background-color: #f7a919;
    border-radius: 5px;
    transition: 0.3s;
}



}







@media (max-width:369px){
   
    .flex3Text a{
        padding: 15px 10px;
        color: white;
        font-weight: bold;
        font-size: 14px;
        background-color: #f7a919;
        border-radius: 5px;
        transition: 0.3s;
    }
    
    
    
    }







    @media (max-width:1065px){


.realGrid{
    grid-template-columns: 1fr 1fr 1fr;

}
    }






    @media (max-width:773px){


        .realGrid{
            grid-template-columns: 1fr 1fr;
  margin-top: 0px !important;
        
        }


        .gridC .circTitle{
            margin-top: 80px;
        }



            }










    @media (max-width:571px){


        .realGrid{
            grid-template-columns: 1fr;
            margin-top: 80px;
        
        }

        .rGrid1 {
         display: flex;
         align-items: flex-start;
         justify-content: flex-start;
         flex-direction: column;
        }

        .rGrid1 img{
        align-self: center;
        }
            }







            @media (max-width:1049px){

                .colorBgText h3{
                    font-size: 40px;
                }
                
                
.colorBgImg img{
    max-width: 400px;
    margin-bottom: -70px;
}


            }










            @media (max-width:961px){

              
                
.colorBgImg img{
    max-width: 360px;
}


            }












            @media (max-width:961px){

                .coloredBgP{
                    margin-bottom: -140px !important;
                    
                    
                  }
                  
                  .bgImage{
                      object-fit: contain;
                      
                  }
                  
              .coloredBgC{
                  display: flex;
                  align-items: flex-start;
                  justify-content: flex-start;
                  flex-direction: column;
                  gap: 100px;
                  position: relative;
              }
              .colorBgImg img{
                margin-bottom: 0px;
                position: absolute;
                top: -20px;
                left: 50;
                max-width: 100%;
                opacity: 0.1;

            }

            .colorBgImg{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
                
                
                            }

  @media (max-width:491px){

    .colorBgText h3{
        font-size: 33px;
    }

    
    .colorBgText p{
        font-size: 18px;
    }
    
    
.colorBgImg img{
max-width: 100%;
}
}





@media (max-width:927px){
    


.contentGrid{
    max-width: 1100px;
    display: grid;
    grid-template-columns: 1fr 1fr ;
    margin: 0 auto;
    gap: 20px;
    row-gap: 40px;
    margin-top: 50px;
}
.contentGrid1 h3{
    font-size: 22px;
}

}








@media (max-width:611px){
    


    .contentGrid{
        max-width: 1100px;
        display: grid;
        grid-template-columns: 1fr 1fr ;
        grid-column-gap: 30px;
        grid-row-gap: 50px;
        
    }
    
    }







@media (max-width:459px){
    


    .contentGrid{
        max-width: 1100px;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 50px;
        
    }

    .contentGrid1{
        width: 100%;
        margin:0 auto;
      
    }
    .contentGrid1 img{
        width: 100%;
    }
    }





    @media (max-width:673px){
        .bgImage{
            display: none;
        }

.darkBg{

    background-color: rgb(245, 245, 245);

}



.wavyBackground{
    display: flex;

}


.flex3P{
    padding-bottom: 120px;
    padding-top: 120px;
    

}


.bottomWavvy{
    bottom: 0px !important;
}


.topWavvy{
    top: 0px !important;

}
.wavyLine{
  display: none;
}



.flex3C{
    z-index: 30;
    color: black;
}

.iconIflex img{
  filter: initial
}


    }



.flex3P{
    position: relative;
    margin-top: 200px;
}


    .wavyLine{
        width:100%;
        position: absolute;
        top: -130px;
        left: 0;

    }



    .wavyBackground{
        position: absolute;
        top: -120px;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }




    @media (max-width:348px){
        .flex3Text h3{
            font-size: 38px;
        }
    }



    .bottomWavvy{
        position: absolute;
        bottom: -60px;
        left: 0;
        width: 100%;
        z-index: 1;

    }

    .topWavvy{
        position: absolute;
        top: -60px;
        left: 0;
        width: 100%;
        z-index: 1;
    }





.flex3P{
    margin-top: 0px !important;
    padding-top: 150px;
}




    
