.footerP{
    margin-top: 70px;
}

.footerC{
    max-width: 1170px;
}
.footerC form{
   
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 60px;
    background-color: white;
    border: 1px solid silver;
    border-radius: 10px;
    border-right: 0px;
    

}


.footerC form input {
    height: 100%;
    width: 100%;
    margin-top: 1px;
    margin-bottom: 1px;
    outline: none !important;
    border:none;

}

.footerC form button{
    height: 100%;
    background-color: #3bb3c5;
    color: white;
    font-weight: bold;
    font-size: 20px;
    padding: 0 25px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border:none


}


.footerC form i{
    font-size: 25px;
     margin: 0 20px;
}




.newsletter{
 display: grid;
 grid-template-columns: 500px 1fr;
    gap: 60px;
    border-top: 1px solid rgba(192, 192, 192, 0.322);
    border-bottom: 1px solid rgba(192, 192, 192, 0.322);

padding-top: 30px;
padding-bottom: 30px;


}

.title{
    font-weight: bold;
    font-size:30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}







footer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 60px;
    margin-bottom: 30px;
}


.aSec{
    display: flex;
    gap: 20px;
    flex-direction: column;
}


.aSec img{
    width: 180px;
}



.social{
    display: flex;
    align-items: center;
    gap: 20px;
   
}

.social a{
    font-size: 23px;
    color: #22a1b4;
}



.bSec{
    display: flex;
    gap: 10px;
    flex-direction: column;
}


.links {
    display: flex;
    gap: 10px;
    flex-direction: column;
}
button{
    cursor: pointer;
}


.links a{
    font-weight: 600;
    opacity: 0.6;
}

.linkTitle{
    font-weight: bold;
    opacity: 0.8;
    font-size: 19px;
}

.linkTitle hr{
    background-color: #3bb3c5;
    margin-top: 5px;
}

.a1 span{
    font-weight: 600;
}


.a1{
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.loca{
    font-weight: 600;
    opacity: 0.6;

}
.a1Icon i{
transform: translateY(3px);
}
.llll .hmmmm{
    font-weight: 600;
    opacity: 0.6; 
}








/* responsive */

@media (max-width:1102px){


.newsletter{
    display: grid;
    grid-template-columns: 400px 1fr;
       gap: 60px;
   
   
   }
}


@media (max-width:928px){
.title{
    display: flex;
    align-items: center;
    justify-content: center;
}
.newsletter{
    display: grid;
    grid-template-columns: 1fr;
       gap: 30px;
   
   
   }


   footer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;

   }
}


@media (max-width:748px){
    footer{
        display: grid;
        grid-template-columns: 1fr;
        gap: 60px;
    
       }
}





.lastFo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid rgba(192, 192, 192, 0.719);
}



.lastFo a:last-child{
    border-left: 1px solid silver;
    margin-left: 20px;
    padding-left: 20px;
}




@media (max-width:673px){
    .lastFo{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        gap: 10px;
    }
}





.llllnewsletter{
    display: none;
}

@media (max-width:366px){
    .title{
      text-align: center;
    }




    .footerC form{

   border-right: 1px solid silver !important;
   

}
.footerC form input{
    border-radius: 10px;
}
  .ffffnewsletter{
    position: relative;
    margin-bottom: 20px;
  }

.newsletter{
    padding-bottom: 90px;
    padding-top: 30px;
}
  .ffffnewsletter button{
      position: absolute;
      width: 100%;
      left: 0;
      max-height: 60px;
      bottom: -70px;
      border-radius: 10px;
      
  }
    .llllnewsletter{
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }

.llllnewsletter form{
    border: 1px solid silver;

}

    .llllnewsletter input{
        border-radius: 10px;

    }

    .llllnewsletter  button{
        height: 100%;
        background-color: #3bb3c5;
        color: white;
        font-weight: bold;
        font-size: 20px;

        padding: 20px 25px;

border-radius: 10px;        border:none
    
    
    }
    
  

}